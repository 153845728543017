* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Medium";
}
