@import "../../assets/scss/config.scss";

.ContainerCard {
  position: relative;
  width: 340px;
  height: 340px;
  border-radius: 10px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .BgFiltre {
    position: absolute;
    background: $Bg-gradient;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 1;

    h1 {
      max-width: 60%;
      color: #ffff;
      font-weight: 500;
      font-size: 18px;
      margin: 20px;
      z-index: 2;
    }
  }

  @media #{$BREAKPOINT-TABLETS} {
    width: 290px;
    height: 290px;

    .BgFiltre {
      h1 {
        font-size: 16px;
        margin: 15px;
      }
    }
  }

  @media #{$BREAKPOINT-MOBILE} {
    width: 90vw;
    height: 255px;

    .BgFiltre {
      h1 {
        font-size: 14px;
        margin: 10px;
      }
    }
  }
}