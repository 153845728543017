@import "../../assets/scss/config.scss";

.ContainerFooter {
  position: relative;

  background-color: $dark;
  bottom: 0;
  color: $secondary;
  height: 209px;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;

  img {
    margin-top: 66px;
    width: 122px;
    color: $secondary;
  }

  p {
    margin-bottom: 29px;
    font-size: 24px;
  }

  @media #{$BREAKPOINT-TABLETS} {
    width: 100vh;
    p {
      font-size: 18px;
    }
  }

  @media #{$BREAKPOINT-MOBILE} {
    p {
      margin-bottom: 15px;
      font-size: 12px;
    }
    width: 100%;
  }

  width: 100%;
}
