@import "../../assets/scss/config.scss";


.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .gallery {
    padding: 50px;
    max-width: 1240px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 43px;
    background-color: $secondary;
    border-radius: 25px;
    gap: 30px;

    @media #{$BREAKPOINT-TABLETS} {
      padding: 30px;
      gap: 20px;
      border-radius: 20px;
      background-color: transparent;
    }

    @media #{$BREAKPOINT-MOBILE} {
      padding: 20px;
      gap: 10px;
      border-radius: 15px;
      background-color: transparent;
      
    }
  }
}