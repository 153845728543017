@import "../../assets/scss/config.scss";

.mainLodging {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 100px 0 100px;

  @media #{$BREAKPOINT-TABLETS} {
    padding: 20px;
  }

  @media #{$BREAKPOINT-MOBILE} {
    padding: 10px;
  }
}

.containerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media #{$BREAKPOINT-MOBILE} {
    flex-direction: column;
  }
}

.titleLodging {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-weight: 500;
  color: $primary;
  margin-top: 20px;

  h1 {
    font-size: 36px;

    @media #{$BREAKPOINT-TABLETS} {
      font-size: 28px;
    }

    @media #{$BREAKPOINT-MOBILE} {
      font-size: 24px;
    }
  }

  .h4 {
    font-size: 18px;
  }

  .tags {
    display: flex;
    padding-top: 20px;
    margin-bottom: 10px;

    .tag {
      margin: 5px;

      font-size: 16px;
      padding: 5px 10px;
      background-color: $primary;
      border-radius: 20px;

      @media #{$BREAKPOINT-TABLETS} {
        font-size: 16px;
        padding: 3px 8px;
      }

      @media #{$BREAKPOINT-MOBILE} {
        font-size: 10px;
        padding: 2px 5px;
        margin: 2px 1;
      }
    }
  }
}

.containerProfil {
  width: 100%;
  display: flex;
  flex-direction: column;

  .starContainer {
    justify-content: space-around;
  }
  @media #{$BREAKPOINT-MOBILE} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.ProfilLodging {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 64px;
    border-radius: 50%;
    align-items: center;
    @media #{$BREAKPOINT-MOBILE} {
      width: 32px;
    }
  }

  p {
    display: flex;
    margin-right: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $primary;
    max-width: 30%;
    justify-content: flex-end;

    @media #{$BREAKPOINT-TABLETS} {
      margin-right: 10px;
    }
    @media #{$BREAKPOINT-MOBILE} {
      font-size: 12px;
    }
  }
}

.lodging-info {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media #{$BREAKPOINT-TABLETS} {
    padding: 10px;
  }
}

.equipementContainer {
  display: flex;
  justify-content: space-between;

  .description,
  .equipments {
    width: 582px;
    height: auto;
  }

  button {
    width: 100%;
    border-radius: 10px;
  }

  .collapse-content {
    font-size: 18px;

    ul {
      list-style-type: none;

      li {
        font-size: 18px;
        color: $primary;
        margin-bottom: 10px;
      }
    }

    @media #{$BREAKPOINT-TABLETS} {
      flex-direction: column;

      .description,
      .equipments {
        width: 450px;
        height: auto;
      }
    }

    @media #{$BREAKPOINT-MOBILE} {
      font-size: 18px;

      ul {
        list-style-type: none;

        li {
          font-size: 12px;
          color: $primary;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media #{$BREAKPOINT-MOBILE} {
    flex-direction: column;

    .description,
    .equipments {
      width: 335px;
      height: auto;
    }
  }
}

img {
  max-width: 100%;
}

.starContainer {
  text-align: right;
  margin-top: 20px;

  @media #{$BREAKPOINT-MOBILE} {
    margin-right: 0;
    margin-bottom: 10px;

    $star-size: 12px;

    .star {
      svg {
        width: $star-size;
        height: $star-size;
        margin-right: 5px;
      }
    }
  }
}
