@import "../../assets/scss/config.scss";

.collapse {
  margin: 10px 0;
  max-width: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-weight: 500;
    background-color: $primary;
    color: #ffffff;
    border: none;
    cursor: pointer;

    img {
      width: 24px;
      height: 13.71px;
      margin-left: 10px;
      transition: transform 0.2s ease-in-out;
    }

    &.open img {
      transform: rotate(180deg);
    }
  }

  .collapse-content {
    font-size: 24px;

    width: 100%;
    padding: 20px;
    background-color: #f6f6f6;
    color: $primary;
    border-top: none;
    border-radius: 0 0 5px 5px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media #{$BREAKPOINT-TABLETS} {
    .collapse-content {
      font-size: 20px;
    }
  }

  @media #{$BREAKPOINT-MOBILE} {
    button {
      font-size: 13px !important;
    }

    .collapse-content {
      font-size: 12px !important;
    }
  }
}
