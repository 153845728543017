@import "../../assets/scss/config.scss";

.SliderContainer {
  display: flex;
  position: relative;
  width: 1240px;
  height: 415px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 25px;
  justify-content: center;
  align-items: flex-end;

  // Styles pour les écrans plus petits
  @media #{$BREAKPOINT-DESK} {
    width: 100%;
  }

  @media #{$BREAKPOINT-TABLETS} {
    height: 250px;
  }

  @media #{$BREAKPOINT-MOBILE} {
    height: 150px;
    border-radius: 0;
  }
}

.SliderContainer img {
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 415px;
  z-index: -1;

  // Styles pour les écrans plus petits
  @media #{$BREAKPOINT-TABLETS} {
    height: 250px;
  }

  @media #{$BREAKPOINT-MOBILE} {
    height: 150px;
  }
}

.SliderContainer button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;

  // Styles pour les écrans plus petits
  @media #{$BREAKPOINT-MOBILE} {
    width: 20px;
    height: 20px;
  }
}

.SliderContainer button img {
  width: 100%;
  height: auto;
}

.SliderContainer button.prev {
  margin: 0;
  left: 20px;

  // Styles pour les écrans plus petits
  @media #{$BREAKPOINT-MOBILE} {
    left: 10px;
  }
}

.SliderContainer button.next {
  margin: 0;
  right: 20px;

  // Styles pour les écrans plus petits
  @media #{$BREAKPOINT-MOBILE} {
    right: 10px;
  }
}

.current-photo-number {
  color: #ffffff;
  font-size: 18px;
  padding: 5px 10px;
  position: absolute;
  padding: 24px;

  // Styles pour les écrans plus petits
  @media #{$BREAKPOINT-TABLETS} {
    font-size: 16px;
    padding: 20px;
  }

  @media #{$BREAKPOINT-MOBILE} {
    font-size: 12px;
    padding: 10px;
  }
}
